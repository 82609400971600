import {environment} from '@vin-decoder/environment';
import {DataStatus, Separator} from 'platform/components';
import {Box, Clickable, Grid, Heading, Image, Show, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';

import {useGetListedCarsQuery} from '../../../../api/carvagoApi';
import {Currency, Locale} from '../../../../api/types/carvagoTypes';
import {useGetVinDecoderData} from '../../../../hooks/useGetVinDecoderData';
import i18n from '../../../../i18n/i18n';
import {CarParameters} from './components/CarParameters';
import {FeatureFlags} from './components/FeatureFlags';
import {MoreSimilarCarsCard} from './components/MoreSimilarCarsCard';
import {PriceRow} from './components/PriceRow';
import {getCarvagoUrl} from './utils/getCarvagoUrl';
import {getFilters} from './utils/getFilters';

export function SimilarCars() {
  const isPrintForced = useParams<{param?: string}>().param === 'pdf';
  const {decoder} = useGetVinDecoderData();

  const headers = {
    locale: i18n.language as Locale | undefined,
    currency: decoder?.data?.currency as Currency | undefined,
  };

  const {relaxedFilters, strictFilters} = getFilters(decoder);
  const skipStrict = isNil(decoder) || isPrintForced;
  const {
    data: strictData,
    isLoading: isStrictLoading,
    isError: isStrictError,
  } = useGetListedCarsQuery({...headers, requestBody: strictFilters}, {skip: skipStrict});

  const isStrictData = strictData && strictData.length > 0;
  const skipRelaxed = skipStrict || isStrictLoading || isStrictError || isStrictData;
  const {
    data: relaxedData,
    isLoading: isRelaxedLoading,
    isError: isRelaxedError,
  } = useGetListedCarsQuery({...headers, requestBody: relaxedFilters}, {skip: skipRelaxed});

  const isLoading = isStrictLoading || isRelaxedLoading;
  const isError = isStrictError || isRelaxedError;
  const data = isStrictData ? strictData : relaxedData;

  return (
    <Show onMobile onTablet onNotebook onDesktop when={!isPrintForced && !isError}>
      <VStack spacing={6}>
        <Heading size={3}>{i18n.t('entity.similarCars.labels.similarCars')}</Heading>
        <DataStatus isLoading={isLoading} minHeight={82}>
          <Grid columns={[1, 2, 4]} spacing={6}>
            {data?.map((car) => (
              <Clickable
                key={car.id}
                onClick={() =>
                  window.open(
                    `${environment.CARVAGO_URL}car/${car.id}/${car.slug}`,
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
                aria-label={`View details for ${car.title || 'car'}`}
                role="link"
              >
                <StyledCard>
                  <VStack>
                    <Image
                      src={car.image?.path}
                      alt={car.title ?? 'Similar car'}
                      ratio="3/2"
                      fit="cover"
                    />
                    <Box padding={4}>
                      <VStack spacing={3}>
                        <Heading size={4}>{car.title}</Heading>
                        <CarParameters car={car} />
                        <FeatureFlags features={car.catalog_features ?? []} />
                        <Separator spacing={0} />
                        <PriceRow car={car} />
                      </VStack>
                    </Box>
                  </VStack>
                </StyledCard>
              </Clickable>
            ))}
            <MoreSimilarCarsCard link={getCarvagoUrl(decoder)} />
          </Grid>
        </DataStatus>
      </VStack>
    </Show>
  );
}

const StyledCard = styled.div`
  border: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  border-radius: ${({theme}) => theme.radii.small};
  overflow: hidden;

  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[20][100]};
  }
`;
