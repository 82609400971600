import {Box, HStack, Show, useWindowDimensions} from 'platform/foundation';

import {useMemo, useRef, useState} from 'react';

import {suffixTestId, TestIdProps, useToggle} from 'shared';

import {CatalogFeature} from '../../../../../api/types/carvagoTypes';
import {Button} from '../../../../../components/Button/Button';
import {Flag} from '../../../../../components/Flag/Flag';
import i18n from '../../../../../i18n/i18n';

const SHOWN_ROWS = 1;
const EXPECTED_MAX_FEATURES_PER_ROW = 5;
const EXPAND_BUTTON_WIDTH = 49;

interface FeatureFlagsProps extends TestIdProps {
  features: CatalogFeature[];
}

export function FeatureFlags(props: FeatureFlagsProps) {
  const features = useMemo(
    () => props.features.filter((feature) => feature.const_key.includes('FEATURE_')),
    [props.features]
  );
  const [isExpanded, toggleExpanded] = useToggle(false);
  const [featuresVisibleCount, setFeaturesVisibleCount] = useState(features.length);
  const lastVisibleElement = useRef<HTMLDivElement>(null);
  const lastVisibleElementIndex = useRef<number>(null);
  const {width: windowWidth} = useWindowDimensions();

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleExpanded();
  };

  const calculateVisibleElement = (ref: HTMLDivElement | null, index: number) => {
    if (ref) {
      const rect = ref.getBoundingClientRect();
      const parentRect = ref.parentElement?.getBoundingClientRect();

      const row = Math.floor((rect.top - (parentRect?.top ?? 0)) / rect.height) + 1;

      if (row === SHOWN_ROWS) {
        lastVisibleElement.current = ref;
        lastVisibleElementIndex.current = index;
      }

      if (row === SHOWN_ROWS + 1) {
        const lastRect = lastVisibleElement.current?.getBoundingClientRect();
        const rightSpace = lastRect && parentRect ? parentRect.right - lastRect.right : 0;

        setFeaturesVisibleCount(
          (lastVisibleElementIndex.current ?? 0) - (rightSpace < EXPAND_BUTTON_WIDTH ? 1 : 0) + 1
        );
      }
    }
  };

  const visibleFeatures = isExpanded ? features : features.slice(0, featuresVisibleCount);
  const hiddenItemsCount = features.length - featuresVisibleCount;
  const hasMoreItems = hiddenItemsCount > 0;

  return (
    <>
      <Box height={0} overflow="hidden" key={`calculator-${windowWidth}`}>
        <HStack spacing={2} wrap>
          {features.slice(0, SHOWN_ROWS * EXPECTED_MAX_FEATURES_PER_ROW).map((feature, index) => (
            <div key={feature.const_key} ref={(ref) => calculateVisibleElement(ref, index)}>
              <Flag label={feature.label} paddingSize="small" />
            </div>
          ))}
        </HStack>
      </Box>
      <HStack spacing={2} wrap>
        {visibleFeatures.map((feature) => (
          <Flag
            key={feature.const_key}
            label={feature.label}
            severity="light"
            paddingSize="small"
            isTextAlternative={false}
          />
        ))}

        <Show when={!isExpanded && hasMoreItems}>
          <Button
            title={`${hiddenItemsCount} +`}
            aria-label={`Show ${hiddenItemsCount} more features`}
            size="xSmall"
            variant="black"
            onClick={handleButtonClick}
            data-testid={suffixTestId('expandButton', props)}
          />
        </Show>

        <Show when={isExpanded && hasMoreItems}>
          <Button
            title={i18n.t('general.actions.hide')}
            size="xSmall"
            variant="black"
            onClick={handleButtonClick}
            data-testid={suffixTestId('collapseButton', props)}
          />
        </Show>
      </HStack>
    </>
  );
}
